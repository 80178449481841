export default function Introduction(){
    return(
    <>
    <div className="jumbotron">
        <h1 className="display-4">Welcome to Cookie Queen's website!
        </h1>
        <p className="lead">I'm Abena, a passionate teenager with a sweet tooth and a knack for baking.</p>
        <hr className="my-4" />      
        <p className="lead">
            To order a cookie or 2, just click  <a className="btn btn-primary btn-lg" href="https://buy.stripe.com/fZefZsbyC0y6cViaEE" role="button">Order Cookies</a>!
           
        </p>
    </div>
    <div className="row mb-3" >
        <div className="col-sm-12" >
            <div className="card custom-card">
            <img src="cookiequeen-colors.jpg" alt="Cookie Queen" className="img-center pt-1"/>
                <div className="card-body">
                <p className="card-text">Thank you for your interest and support of my cookie business. 
                I started this business when I was 10 years old because I l😍ve chocolate.
                My homemade chocolate chip cookies are baked fresh with love and the finest ingredients.
                   
                     I hope you enjoy my chocolate treats as much as I do..</p>
                </div>
            </div>
        </div>
        <div className="col-sm-12 mt-1"  >
            <div className="card custom-card-right" >
                <div className="card-body">
                    <p className="card-title">FAQs</p>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <h5>When Do You Deliver?</h5>
                            <p>During school months, (Sept - June), I deliver every Saturday and Sunday between 9AM and 12PM.</p>
                        </li>
                        <li className="list-group-item">
                            <h5>How Do I Get My Deliveries?</h5>
                            <p>After your order is confirmed, I will call you to verify/confirm your delivery address.</p>
                        </li>
                        <li className="list-group-item">
                            <h5>Where Do You Deliver?</h5>
                            <p>During school months, (Sept - June), I only deliver to District Heights, MD unless it is a catering order.</p>
                        </li>
                        <li className="list-group-item">
                            <h5>How Do I Order?</h5>
                            <p>Simple, just click  <a href="https://buy.stripe.com/fZefZsbyC0y6cViaEE" className="btn btn-primary btn-sm" > Order Cookies</a>  to make a secure purchase.</p>
                        </li>
                        <li className="list-group-item">
                            <h5>Do You Cater?</h5>
                            <p>Yes. Just send me a message to <a href="mailto:chocolatecitytech@gmail.com"> chocolatecitytech@gmail.com </a> and I'll call you to confirm and make arrangements. Thank you.</p>
                        </li>
                    </ul>  
                </div>
            </div>
        </div>
    </div>
    </>)
}